.service_img {
  height: 250px !important;
  background: url("../../assets/img/contact-us/bg-image-about-us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: brightness(0.6); */
}

@media (max-width: 1399.98px) {
  .service_img {
    height: 220px !important;
  }
}
@media (max-width: 1199.98px) {
  .service_img {
    height: 205px !important;
  }
}
@media (max-width: 991.98px) {
  .service_img {
    height: 195px !important;
  }
}
@media (max-width: 767.98px) {
  .service_img {
    height: 185px !important;
  }
}
@media (max-width: 575.98px) {
  .service_img {
    height: 180px !important;
  }
}

.career_box {
  border-radius: 15px !important;
}

.career_box:hover {
  outline: 2px solid rgba(255, 3, 62, 0.285) !important;
  box-shadow: rgba(255, 3, 62, 0.285) 0px 7px 20px 0px !important;
}

.industries_box {
  outline: 1px solid rgba(128, 128, 128, 0.183);
}

.industries_box:hover {
  outline: 1px solid rgba(255, 3, 62, 0.285) !important;
  box-shadow: rgba(255, 3, 62, 0.285) 0px 7px 20px 0px !important;
}

.expertise_carusal_card {
  border-radius: 15px;
  background-color: orangered;
  position: relative;
}
.expertise_carusal_card:after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: orangered;
  transform: rotate(45deg);
  left: 40px;
  top: -9px;
}

.expertise_box {
  border-radius: 0px !important;
}

.expertise_box:hover {
  outline: 2px solid rgba(255, 3, 62, 0.285) !important;
  box-shadow: rgba(255, 3, 62, 0.285) 0px 7px 20px 0px !important;
}
