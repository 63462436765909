@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif !important;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

.links {
  text-decoration: none !important;
  color: rgb(255, 60, 0) !important;
}

.dropdown-toggle::after {
  content: none !important;
}

/* salesforce dropdown */
.salesforce-dropdown {
  background-color: rgba(183, 180, 180, 0.196);
}

/*ROOTS=======================VARIABLES============================START*/
:root {
  /*FONT===SIZES*/
  --font--sm: 14px;
  --font--normal: 16px;
  --font--md: 18px;
  --font--lg: 20px;
  --font--xl: 31px;
  --font--xxl: 34px;
  --font--2xl: 48px;
  /*COLORS*/
  --white: #ffffff;
  --black: #000000;
  --light-black: #010914;
  --red: #ff0000;
  --light-grey: #ececf5;
  --blue: #f2fbff;
  --pink: #fff5f5;
}

/*MEDIA=====================QUERY=====================CLASSES=====================START*/
@media (max-width: 1399.98px) {
  :root {
    --font--sm: 14px;
    --font--normal: 16px;
    --font--md: 18px;
    --font--lg: 20px;
    --font--xl: 31px;
    --font--xxl: 39px;
    --font--2xl: 48px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    --font--sm: 14px;
    --font--normal: 16px;
    --font--md: 18px;
    --font--lg: 20px;
    --font--xl: 28px;
    --font--xxl: 35px;
    --font--2xl: 40px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font--sm: 14px;
    --font--normal: 16px;
    --font--md: 18px;
    --font--lg: 20px;
    --font--xl: 25px;
    --font--xxl: 30px;
    --font--2xl: 36px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --font--sm: 14px;
    --font--normal: 16px;
    --font--md: 18px;
    --font--lg: 20px;
    --font--xl: 22px;
    --font--xxl: 25px;
    --font--2xl: 32px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font--sm: 12px;
    --font--normal: 12px;
    --font--md: 16px;
    --font--lg: 18px;
    --font--xl: 20px;
    --font--xxl: 22px;
    --font--2xl: 28px;
  }
  .feedbacktopwave {
    top: -2%;
  }
}

/*MEDIA=====================QUERY=====================CLASSES=====================END*/
/*FONT=====================SIZES====================CLASSES=====================START*/
.font-sm {
  font-size: var(--font--sm) !important;
}

.font-normal {
  font-size: var(--font--normal) !important;
}

.font-md {
  font-size: var(--font--md) !important;
}

.font-lg {
  font-size: var(--font--lg) !important;
}

.font-xl {
  font-size: var(--font--xl) !important;
}

.font-xxl {
  font-size: var(--font--xxl) !important;
}

.font-2xl {
  font-size: var(--font--2xl) !important;
}

/*FONT=====================SIZES====================CLASSES=====================END*/
/*FONT=====================WEIGHT=====================CLASSES====================START*/
.fw-normal {
  font-weight: normal !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-extrabold {
  font-weight: 800 !important;
}

/*FONT=====================WEIGHT=====================CLASSES====================END*/
/*COLORS====================CLASSES=========================START*/
.white {
  color: var(--white) !important;
}

.black {
  color: var(--black) !important;
}

.red {
  color: var(--red) !important;
}

.bg_red {
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%);
}

.bg_blue {
  background-color: var(--blue) !important;
}

.bg_pink {
  background-color: var(--pink) !important;
}

.light-grey {
  background-color: var(--light-grey) !important;
}

/*COLORS====================CLASSES=========================END*/
/*COMMON===================CLASSES=====================START*/
.main_heading {
  font-size: var(--font--xxl);
  font-weight: 700;
  color: var(--white);
}

.main_para {
  font-size: var(--font--normal);
  font-weight: 400;
  color: var(--black);
}

.common_btn {
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%), #c4c4c4;
  border-radius: 87px;
  color: white !important;
  border: 1px solid transparent;

  font-size: var(--font--sm);
}

.mobile_sign_up {
  background: linear-gradient(302.28deg, #ffffff 0%, #e3caca 95.8%), #c4c4c4;
  border-radius: 87px;
  color: #ec1616 !important;
  border: 1px solid transparent;
  font-size: var(--font--sm);
}

.cmn_btn_border {
  background: transparent;
  border-radius: 87px;
  color: white !important;
  border: 1px solid white !important;
  font-size: var(--font--sm);
}
.cmn_desk_btn_border {
  background: transparent;
  border-radius: 87px;
  color: black !important;
  border: 1px solid #ec1616 !important;
  font-size: var(--font--sm);
}

.common_btn:hover {
  color: linear-gradient(302.28deg, #3d3e99 0%, #00b1f5 95.8%);
  background-color: white !important;
}
.slider_btn {
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%), #c4c4c4;
  color: white !important;
  border: 1px solid #ef4f2d;
  border-radius: 59px;
  font-size: var(--font--sm);
  border: none;
}

.slider_btn:hover {
  color: linear-gradient(302.28deg, #ef4f2d 0%, #f8a225 95.8%);
  background-color: white !important;
}
.slider2_btn {
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%), #c4c4c4;
  color: white !important;
  border: 1px solid #ec1616;
  border-radius: 59px;
  font-size: var(--font--sm);
}

.slider2_btn:hover {
  color: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%);
  background-color: white !important;
}
.hero-btn {
  background: linear-gradient(302.28deg, #ef4f2d 0%, #f8a225 95.8%);
  border-radius: 5px;
  color: white !important;
  font-size: var(--font--lg);
}
.hero-btn:hover {
  color: linear-gradient(302.28deg, #ef4f2d 0%, #f8a225 95.8%);
  background-color: white !important;
}
/*COMMON===================CLASSES=====================END*/

/*TEAM====================SECTION=====================CSS=========================START*/
.team_img {
  border-radius: 50%;
}

.team_bg {
  /* background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px; */
  transition: all 0.4s ease-in-out;
}

.team_bg:hover {
  transform: translateY(-10px);
}

.team_members {
  transition: 0.4s;
}

.team_members:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

/*TEAM====================SECTION=====================CSS=========================END*/

/*FORM===============================SECTION===========================CSS=================================START*/
.form_bg {
  background: #fff5f5;
}

.form_btn {
  padding: 14px 41px;
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%), #c4c4c4;
  border-radius: 87px;
  border: 1px solid transparent;
  transition: 0.4s;
}

.form_btn:hover {
  transform: translateY(-10px);
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: green !important;
  outline: none !important;
  box-shadow: none !important;
}

.margin-top-minus {
  margin-top: 0px;
  margin-bottom: 0px;
}

/*FORM===============================SECTION===========================CSS=================================END*/

/*TOPHEADER==============================================SECTION====================CSS======================START*/
.social_icons {
  transition: 0.4s;
  cursor: pointer;
}

.social_icons:hover {
  transform: translateY(-8px);
}

/*TOPHEADER==============================================SECTION====================CSS======================END*/
/* ============================================= FOOTER SECTION======================================================== */
.footer-section {
  background: var(--black);
}

.opacity_07 {
  opacity: 0.7;
}

.footer-line {
  height: 2px;
  width: 100%;
  opacity: 0.7;
  background-color: var(--white);
}

/* HEADER OVERLAY STYLES  */

.navbar-wrapper .nav-list-items a {
  color: white;
  text-decoration: none;
  position: relative;
  font-size: var(--font--sm);
}

.navbar-wrapper .nav-list-items a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--black);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-wrapper .nav-list-items a:hover::after {
  width: 100%;
}

/* dropdown second layer */

.dropdown-second-layer {
  /* style={{position:'absolute', left:'10.5rem', top:'4.5rem', buttom:'0', backgroundColor:'#FFFFFF', zIndex:'800'}} */
  position: absolute;
  left: 10.5rem;
  top: 4.5rem;
  background-color: #ffffff;
  z-index: 999;
  border-radius: 3px;
}

.salse-menu {
  padding: 5px 15px;
}

.salse-menu:hover {
  background: rgba(128, 128, 128, 0.114);
}

/* HAMBURGER ICON STYLE  */
@media (max-width: 991.98px) {
  /* NAVBAR OVERLAY TOGGLE */

  .navbarwrapper {
    position: relative;
    width: 29px;
    cursor: pointer;
    height: 25px;
    z-index: 15;
  }

  .first {
    background-color: var(--black);
    width: 100%;
    height: 3px;
    position: absolute;
    border-radius: 20px;
    right: 0;
  }

  .second {
    background-color: var(--black);
    width: 70%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 10px;
  }

  .third {
    background-color: var(--black);
    width: 100%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 20px;
  }

  .animate .second {
    opacity: 0;
  }

  .animate .first {
    top: 11px;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .animate .third {
    top: -8px;
    transform: rotate(-45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }
}

@media (max-width: 991.98px) {
  .overlay-nav {
    transition: 0.7s ease !important;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.overlay-active {
  z-index: 10;
  transition: 0.7s ease !important;
  opacity: 1;
  pointer-events: all;
}

.pt-30 {
  padding-top: 30px;
}

.object-fit {
  object-fit: contain;
}

.mb-80 {
  margin-bottom: 80px;
}

.p-10 {
  padding: 10px;
}

.overlay-nav li {
  transform: translateY(10px);
  transition: all 0.4s ease-in-out;
  opacity: 0;

  list-style-type: none;
}

.overlay-nav .nav-link {
  font-size: var(--font--normal) !important;
}

.overlay-active ul li:nth-child(1) {
  animation: animate_links 0.9s;
  animation-delay: 0.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(2) {
  animation: animate_links 0.9s;
  animation-delay: 0.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(3) {
  animation: animate_links 0.9s;
  animation-delay: 0.6s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(4) {
  animation: animate_links 0.9s;
  animation-delay: 0.8s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(5) {
  animation: animate_links 0.9s;
  animation-delay: 1s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(6) {
  animation: animate_links 0.9s;
  animation-delay: 1.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.overlay-active ul li:nth-child(7) {
  animation: animate_links 0.9s;
  animation-delay: 1.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.overlay-active ul li:nth-child(8) {
  animation: animate_links 0.9s;
  animation-delay: 1.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.overlay-active ul li:nth-child(9) {
  animation: animate_links 0.9s;
  animation-delay: 1.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes animate_links {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(-20px);
    opacity: 1;
  }
}

.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}

/* HEADER OVERLAY STYLES  */
/* ====================================================== TOP HEADER SECTION ============================================ */
.top-header-section {
  background-color: #ee3131;
}
/* ==================================================== TEAM CARDS SECTION ============================================ */
.teamcard {
  background-color: #fbfbfb;
}
.teamcard .colored-bg {
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%), #c4c4c4;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: 0.4s all ease-in-out;
}
.box-shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}
.margin-bottom-19 {
  margin-bottom: -19px;
}
.teamcard {
  transition: 0.6s all ease-in-out;
}
.teamcard:hover .colored-bg {
  opacity: 1;
}
/* ===================================================== HERO SECTION =================================================== */
.hero-slider .carousel-indicators .active {
  height: 13px !important;
  width: 13px !important;
  border-radius: 50% !important;
  border: 2px solid #ee3131;
  background-color: #ee3131 !important;
}

.hero-slider .carousel-indicators [aria-current="false"] {
  height: 13px !important;
  width: 13px !important;
  border-radius: 50% !important;
  border: 2px solid #ee3131;
  background-color: transparent !important;
}
.hero-slider .carousel-indicators {
  margin-bottom: 2rem !important;
}
@media (max-width: 575.98px) {
  .hero-slider .carousel-indicators {
    margin-bottom: 0.8rem !important;
  }
}
.hero-img {
  min-height: 100vh;
  object-fit: cover;
}

.learn-more-btn {
  background-color: transparent;
  color: var(--white);
  font-size: var(--font--normal);
  border-radius: 59px;
  border: 1px solid var(--white);
}

.btn_hover {
  transition: 0.4s;
}

.btn_hover:hover {
  transform: translateY(-6px);
}
.learn-more-btn2 {
  background-color: transparent;
  color: #ee3131;
  font-size: var(--font--normal);
  border-radius: 59px;
  border: 1px solid #ee3131;
}

.slider_hover {
  transition: 0.4s;
  cursor: pointer;
}

.slider_hover:hover {
  transform: scale(1.04);
}
.hero-card {
  background-color: var(--white);
  border-radius: 16px;
}
.input-field {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 132px;
  outline: none;
}
.input-field::placeholder {
  font-size: var(--font--lg);
  color: #000000;
  font-weight: 400;
  opacity: 0.7;
}
.form-check-input:focus {
  border-color: var(--black) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent !important;
}
/* ================================================= FEEDBACK SECTION ============================================ */
.feedback-bg-img {
  background: #fff5f5;
}

/* .width_485 {
  width: 485px;
} */
@media (min-width: 767.98px) {
  .width_485 {
    width: 485px;
  }
}

/* ================================================= CONTACT SECTION =============================================== */
.contact-section {
  background-image: url("../img/contact/contact-bg.webp");
  background-size: cover;
}

/* ================================================= CONTACT SECTION ============================================= */
.contact-input {
  outline: none;
}

.h-100vh {
  height: 100vh;
}

.feedback-slider .slick-prev:before {
  background-image: url("../img/feedback/icon-left.webp") !important;
  background-repeat: no-repeat !important;
  font-size: 43px;
  color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.feedback-slider .slick-next:before {
  background-image: url("../img/feedback/icon-right.webp") !important;
  background-repeat: no-repeat !important;
  font-size: 43px;
  color: transparent;
}

.margin-top-minus-industries {
  margin-top: 0px;
  margin-bottom: 0px;
}

.margin-top-minus-feedback {
  margin-top: 0px;
  margin-bottom: 0px;
}

.margin-top-minus-form {
  margin-top: 0px;
  margin-bottom: 0px;
}

.margin-top-minus-footer {
  margin-top: 0px;
  margin-bottom: 0px;
}

.topscroll {
  position: fixed;
  right: 25px;
  bottom: 45px;
  background: linear-gradient(302.28deg, #ec1616 0%, #e16a6a 95.8%);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  animation: move 2s ease-in-out infinite;
}

.hover_icons {
  transition: 0.4s;
}

.hover_icons:hover {
  transform: translateY(-4px);
}

/* ================================================== MEDIA QUERIES =============================================== */
/* @media (max-width: 991.98px) {
  .hero-img {
    min-height: 80vh;
  }
} */
.dropdown .btn-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px 0px !important;
}
.dropdown a {
  font-size: var(--font--sm);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  font-weight: bold;
}
.dropdown-item {
  color: var(--black) !important;
  opacity: 0.7;
}
.dropdown .btn-primary:focus {
  box-shadow: 0 0 0 0.25rem transparent !important;
}

@media (max-width: 575.98px) {
  .margin-top-minus {
    margin-top: -2px;
    margin-bottom: -5px;
  }

  .margin-top-minus-industries {
    margin-top: -3px;
    margin-bottom: -5px;
  }

  .margin-top-minus-feedback {
    margin-top: 0px;
    margin-bottom: -4px;
  }

  .margin-top-minus-form {
    margin-top: -3px;
    margin-bottom: -6px;
  }

  .margin-top-minus-footer {
    margin-top: -1px;
    margin-bottom: -5px;
  }

  .topscroll {
    height: 40px;
    right: 10px;
    bottom: 30px;
    width: 40px;
  }
  .top-header-section {
    display: none;
  }
}

@media (max-width: 400px) {
  .margin-top-minus-form {
    margin-top: -5px;
    margin-bottom: -8px;
  }

  .margin-top-minus-footer {
    margin-top: -2px;
    margin-bottom: -5px;
  }
  .top-header-section {
    display: none;
  }
}

.slider_hover_hidden {
  overflow: hidden;
  object-fit: cover;
}

.setClass .open_overlay_sections_none {
  display: none !important;
}

.hoverline {
  display: inline-block;
  position: relative;
}

.hoverline:after {
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  background: white;
  opacity: 0.7;
  transition: 0.3s ease-in-out;
  width: 0;
}

.hoverline:hover:after {
  transform: scaleX(1);
  width: 100%;
  left: 0;
}

.hoverline_black {
  display: inline-block;
  position: relative;
}

.hoverline_black:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: black;
  transition: 0.3s ease-in-out;
  width: 0;
}

.hoverline_black:hover:after {
  transform: scaleX(1);
  width: 100%;
  left: 0;
}
.moving_animation_img {
  animation: updowns 3s ease-in-out infinite;
}

@keyframes updowns {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}
.card_box {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  transition: all 0.4s ease-in-out;
}

.card_box:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  transform: translateY(-10px);
}
.learn_btn {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 102px;
}
.learn_btns {
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.learn_btns:hover {
  transform: scale(1.1);
}
.ittalic {
  font-style: italic;
}
@media (max-width: 991.98px) {
  .object_position-left {
    object-position: left;
  }
  .top-header-section {
    display: none;
  }
}
@media (min-width: 992px) {
  .MapImg {
    opacity: 0 !important;
  }
  .man_img {
    position: relative;
    z-index: 100;
    margin-top: -5%;
  }
}

.feedbacktopwave {
  top: -1%;
}
.card_shadow {
  transition: all 0.4s ease-in-out;
  border-radius: 12px;
  border: 1px solid transparent;
}
.card_shadow:hover {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  transform: translateY(-10px);
}

.FromTopWave {
  margin-bottom: -1%;
}
.FromBottomWave {
  margin-top: -2%;
}

.contact-info {
  height: 12rem;
}

.MapImg2 {
  /* width: 712px; */
  height: 450px;
}
@media (min-width: 1200px) {
  .MapImg2 {
    /* width: 712px; */
    height: 450px;
  }
}

input {
  font-size: 16px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 0, 0, 0.373) !important;
  background: transparent !important;
}

textarea {
  font-size: 16px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 0, 0, 0.373) !important;
  background: transparent !important;
}

/* dropdown desktop */
.medium_menu {
  margin-top: 20px;
}

.phone_menu {
  min-width: 35vw !important;
  padding: 7px !important;
  background-color: black !important;
  animation: animate_links 0.9s;
  animation-delay: 0.8s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 9999 !important;
}

.sub_menu {
  /* font-size: 16px !important; */
  color: #ec1616 !important;
  text-decoration: none !important;
}

.form-padding {
  padding-bottom: 70px !important;
}

.modal-header {
  border-bottom: none;
}

.modal-dialog {
  max-width: 30%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.modal-dialog:after {
  content: "";
  position: absolute;
  background: url("../img/login/subs-elements.png");
  /* z-index: -1; */
  width: 50px;
  height: 50px;
  bottom: -25px;
}

.modal-content {
  border-radius: 20px;
}

.login_form {
  /* border: none !important; */
  border-radius: 50px !important;
  box-shadow: rgba(173, 173, 188, 0.245) 0px 30px 60px -12px inset,
    rgba(217, 216, 216, 0.077) 0px 18px 36px -18px inset !important;
  text-align: center;
}

.login_form:focus {
  box-shadow: rgba(173, 173, 188, 0.245) 0px 30px 60px -12px inset,
    rgba(217, 216, 216, 0.077) 0px 18px 36px -18px inset !important;
}

.trusted_slider {
  max-height: 80px;
}

.react_slick {
  height: 25px;
}

.react_slick .slick-list {
  margin: 0 -10px;
}

.react_slick .slick-list .slick-track {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.react_slick .slick-list .slick-track .slick-slide {
  margin: 0 30px;
}

.react_slick .slick-list .slick-track .slick-slide div {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react_slick .slick-list .slick-track .slick-slide div img {
  width: 80% !important;
}

.hero_img {
  width: 80%;
}

@media (max-width: 1399.98px) {
  .modal-dialog {
    max-width: 25%;
  }
  .hero_img {
    width: 80%;
  }
}

@media (max-width: 1199.98px) {
  .modal-dialog {
    max-width: 50%;
  }
  .hero_img {
    width: 77%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 45%;
  }
  .hero_img {
    width: 75%;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 65%;
  }
  .react_slick .slick-list .slick-track .slick-slide div img {
    width: 100% !important;
  }
  .hero_img {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 85%;
  }
  .react_slick .slick-list .slick-track .slick-slide div img {
    width: 100% !important;
  }
  .hero_img {
    width: 100%;
  }
}

/* salseforce */
.salse-grid-box {
  border-top: 3px solid rgba(255, 0, 0, 0.789);
}
